<template>
  <div>
    <b-modal
      :id="id"
      :hide-footer="true"
      centered
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      :title="$route.params.id === undefined ? 'thêm loại hình doanh nghiệp' : 'chỉnh sửa loại hình doanh nghiệp'"
      @hidden="close"
    >
      <div class="form-input">
        <validation-observer ref="rule">
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputCode"
              >Mã loại hình doanh nghiệp <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="{required: 'Mã loại hình doanh nghiệp là bắt buộc'}"
              >
                <b-form-input
                  id="basicInputCode"
                  v-model=" dataValue.code "
                  :state="errors.length > 0 ? false:null"
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputName"
              >Tên loại hình doanh nghiệp <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="{required: 'Tên loại hình doanh nghiệp là bắt buộc'}"
              >
                <b-form-input
                  id="basicInputName"
                  v-model=" dataValue.name "
                  :state="errors.length > 0 ? false:null"
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <label for="code">Loại hình thống kê <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="bType"
                rules="required"
                :custom-messages="{required: 'Loại hình thống kê là bắt buộc'}"
              >
                <v-select
                  v-model="dataValue.bType"
                  :reduce="item => item.businessType"
                  :state="errors.length > 0 ? false:null"
                  label="businessTypeString"
                  :options="dataBType || []"
                  placeholder="Lựa chọn loại hình thống kê"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div>
            <b-form-group>
              <label for="basicInputName">Mô tả</label>
              <b-form-textarea
                id="basicInputName"
                v-model="dataValue.description"
                placeholder=""
                rows="3"
                no-resize
              />
            </b-form-group>
          </div>
        </validation-observer>
      </div>
      <div class="action">
        <b-button
          v-if="!isNotification"
          variant="outline-primary mr-1 "
          @click="cancel"
        >
          Hủy bỏ
        </b-button>
        <b-button
          variant="primary"
          class="btn-left"
          @click="accept"
        >
          Lưu lại
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCardText, BButton, BFormGroup, BForm, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BCardText,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BCol,
    BFormInput,
    BFormTextarea,
  },
  props: {
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: String,
      default: 'confirm-modal',
    },
    // nếu là thông báo không có nút cancel
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataValue: {
        code: '',
        name: '',
        description: '',
        bType: '',
      },
      checkCodeDT: '',
      checkNameDT: '',
      dataBType: [
        {
          businessType: 'StateEnterprises',
          businessTypeString: 'Doanh nghiệp nhà nước',
        },
        {
          businessType: 'LimitedLiabilityCompany',
          businessTypeString: 'Công ty trách nhiệm hữu hạn',
        },
        {
          businessType: 'JointStockCompany',
          businessTypeString: 'Công ty cổ phần',
        },
        {
          businessType: 'PrivateEnterprise',
          businessTypeString: 'Doanh nghiệp tư nhân',
        },
        {
          businessType: 'ForeignInvestedEnterprises',
          businessTypeString: 'Doanh nghiệp có vốn đầu tư nước ngoài',
        },
        {
          businessType: 'CoOperative',
          businessTypeString: 'Hợp tác xã',
        },
        {
          businessType: 'Partnerships',
          businessTypeString: 'Công ty hợp danh',
        },
        {
          businessType: 'Another',
          businessTypeString: 'Khác',
        },
      ],
    }
  },
  computed: {
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.id) {
        this.getDataEdit()
      }
    },
  },
  methods: {
    //* **lấy dữ liệu edit** */
    async getDataEdit() {
      const resuft = await axiosApiInstance.get(`${ConstantsApi.FETCH_TO_ID}${this.$route.params.id}`)
      if (resuft.status === 200) {
        this.dataValue.name = resuft.data.name
        this.dataValue.code = resuft.data.code
        this.dataValue.description = resuft.data.description
        this.dataValue.bType = resuft.data.bType
      }
    },
    //* **Kiểm tra mã trùng** */
    // eslint-disable-next-line vue/no-dupe-keys
    async checkCode() {
      const res = await axiosApiInstance.get(ConstantsApi.CHECK_CODE_STORE, { params: { code: this.dataValue.code, id: this.$route.params.id } })
      if (res.status === 200) {
        this.checkCodeDT = res.data
        if (res.data === true) {
          this.$root.$bvToast.toast('Mã bị trùng', {
            title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
          })
        }
      }
    },
    //* **Kiểm tra tên trùng** */
    // eslint-disable-next-line vue/no-dupe-keys
    async checkName() {
      const res = await axiosApiInstance.get(ConstantsApi.CHECK_NAME_STORE, { params: { name: this.dataValue.name, id: this.$route.params.id } })
      if (res.status === 200) {
        this.checkNameDT = res.data
        if (res.data === true) {
          this.$root.$bvToast.toast('Tên bị trùng', {
            title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
          })
        }
      }
    },
    //* **phần thêm** */
    async add() {
      try {
        this.$showAllPageLoading()
        const resuft = await axiosApiInstance.post('/BussinessType/bussinessTypes', this.dataValue)
        if (resuft.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } catch (e) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm không thành công!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    async edit() {
      try {
        this.$showAllPageLoading()
        const res = await axiosApiInstance.put('/BussinessType/bussinessTypes', this.dataValue)
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Sửa thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } catch (e) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Sửa không thành công!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    async accept() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          await this.checkName()
          await this.checkCode()
          if (this.checkNameDT === false && this.checkCodeDT === false) {
            if (this.$route.params.id) {
              this.dataValue.id = this.$route.params.id
              await this.edit()
              this.$bvModal.hide(this.id)
              this.$emit('callAPI')
            } else {
              await this.add()
              this.$bvModal.hide(this.id)
              this.$emit('callAPI')
            }
          }
        }
      })
    },
    close() {
      this.dataValue = {
        code: '',
        name: '',
        description: '',
        bType: '',
      }
      if (this.$route.params.id) {
        this.$router.push({ name: 'type-of-business' })
      }
    },
    cancel() {
      this.dataValue = {
        code: '',
        name: '',
        description: '',
        bType: '',
      }
      if (this.$route.params.id) {
        this.$router.push({ name: 'type-of-business' })
      }
      this.$bvModal.hide(this.id)
      this.$emit('cancel')
    },
  },
}
</script>
<style scoped>
.action{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px 20px 20px;
  margin-top: 20px;
  border-top: 1px solid #CBCDD2 ;
}
</style>
