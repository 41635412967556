<template>
  <div>
    <div class="page-container-table">
      <!--=============== Bắt đầu phần header trên cùng================= -->
      <button-all-header
        :contentBtnAdd="'Thêm loại hình doanh nghiệp'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteMore"
        @clickDowloadSample="downloadSampleUserGroup"
        @clickExportExcel="downloadExcelAll"
        @importFile="importFileExcel($event)"
        @clickAdd="$bvModal.show('createTypeBussiness')"
        @search="search($event)"
      />
      <!--=============== Kết thúc phần header trên cùng================= -->
      <!--=================== Bắt đầu phần bảng==================== -->
      <div class="User-Group__main__table">
        <!--================================= Bảng vue good table ====================== -->
        <vue-good-table
          style-class="vgt-table"
          :columns="columns"
          :rows="rows"
          :sort-options="{
            enabled: false,
            initialSortBy: [
              {field: 'name', type: 'asc'},
              {field: 'code', type: 'desc'}
            ],
          }"
          :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
          @on-selected-rows-change="selectRowTable"
        >
          <!--=================== chỉnh sửa phần chức năng trong bảng==================== -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'cn'">
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="EditIcon"
                size="18"
                class="table__chucnang__icon"
                @click="edit(props.row.id)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="table__chucnang__icon"
                style="margin-left: 14px"
                @click="handleClickDeleteButton(props.row.id)"
              />
            </span>
          </template>
          />
        </vue-good-table>
      </div>
      <!--=================== Kết thúc phần bảng=================== -->
      <div class="User-Group__main__footer">
        <my-pagination
          :totalItems="totalPages"
          :currentPage="urlQuery.pageNumber"
          @pageClick="handlePageClick"
        />
      </div>
    </div>
    <Add
      :id="'createTypeBussiness'"
      @callAPI="fetchdata()"
    />
    <ModalDeleteUserType
      :id="'delete-modal'"
      :comboBoxDel="allPosition"
      :name="'loại hình doanh nghiệp'"
      @accept="handelDelete"
    />
  </div>
</template>

<script>
// =====================================================Phân import file ======================================//
import VSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapState, mapMutations } from 'vuex'
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-unresolved
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Add from './components/Add.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import ModalDeleteUserType from './components/ModalDeleteUserType.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    MyPagination,
    Search,
    VSelect,
    Add,
    ToastificationContent,
    ModalDeleteUserType,
    ButtonAllHeader,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  //= =========================== dữ liệu data ==========================//
  data() {
    return {
      IdEdit: '',
      addBusiness: 'addBusiness',
      editBusiness: 'editBusiness',
      showBtnMultiDelete: false,
      selectTable: '',
      deleteModel: {
        id: '',
        positionId: '',
      },
      allPosition: '',
      currentPage: 1, // ========================trang hiện tại==============================//
      dataTable: '', // ============mảng trả về dữ liệu của bảng để hiển thị ===============//
      selectDown: [5, 10, 20], //= ==========Lựa chọn số trang hiên thị =====================//
      perpage: 5, //= ====================  Số trang hiển thị trên bảng =========================//
      totalPages: '', // =====================  Tổng só trang   ====================================//
      valueInput: '', //= =======================Dữ liệu từ input seach ==============================//
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Mã loại hình doanh nghiệp',
          field: 'code',
          tdClass: 'style-code',
        },
        {
          label: 'Tên loại hình doanh nghiệp',
          field: 'name',
        },
        {
          label: 'Chức năng',
          field: 'cn',
          width: '175px',
          tdClass: 'table__chucnang',
          thClass: 'table__chucnang',
        },
      ],
      rows: [
      ],
      arrayExcel: ['code', 'name', 'bType', 'description'],
    }
  },
  // =====================================Phần Created=================================//
  async created() {
    await this.fetchdata()
  },
  // =====================================Phần methods ================================//
  methods: {
    ...mapActions('typeOfBusiness', ['downloadSampleUserGroup', 'getApiExcel', 'getAllComboBox', 'fetchUserGroupToId', 'deleteUserGroup', 'downloadExcelAll']),
    ...mapMutations('typeOfBusiness', ['DATA_EDIT_USER_GROUP']),
    // ***Call API ***//
    // ***Lấy dữ liệu bảng***/
    // eslint-disable-next-line consistent-return
    async fetchByofBusiness(data) {
      try {
        const resuft = await axiosApiInstance.get(ConstantsApi.FETCH_BY_OF_BUSINESS, { params: data })
        if (resuft.status === 200) {
          return resuft.data
        }
      } catch (e) {
        return null
      }
    },
    // ========================================================lấy dũ liệu bảng =============================================//
    async fetchdata() {
      this.$showLoading()
      const datafetchUserGroup = await this.fetchByofBusiness(this.urlQuery)
      this.rows = datafetchUserGroup.data.pageLists
      this.totalPages = datafetchUserGroup.data.totalRecord
      this.$hideLoading()
    },
    // =========================================================Phần phân trang ==========================================
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchdata()
    },
    // ============================================================Phân tìm kiếm -==========================================
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        await this.fetchdata()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        await this.fetchdata()
      }
    },
    // ==========================================================import file exel ================================//
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    //= ================================================kiểm tra hợp lệ từ file exel========================//
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'type-of-business-list' })
    },

    // ***Phần sửa***//
    edit(id) {
      this.IdEdit = id
      this.$bvModal.show('createTypeBussiness')
      this.$router.push({ name: 'type-of-business-edit', params: { id } })
    },
    // ***Chọn dữ liệu bảng***//
    selectRowTable(val) {
      this.deleteModel.id = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteModel.id.length > 0
    },
    // ***Kích vào nút xóa***//
    async handleClickDeleteButton(id) {
      this.deleteModel.id = [id]
      const comboBox = await this.getAllComboBox(this.deleteModel.id)
      this.allPosition = comboBox.data.data
      this.$bvModal.show('delete-modal')
    },
    async deleteMore() {
      const comboBox = await this.getAllComboBox(this.deleteModel.id)
      this.allPosition = comboBox.data.data
      this.$bvModal.show('delete-modal')
    },
    async handelDelete(e) {
      this.deleteModel.positionId = e

      const response = await this.deleteUserGroup(this.deleteModel)
      if (response.status === 200) {
        await this.fetchdata()
      }
      this.$root.$bvToast.toast('Xóa thành công', {
        title: 'Thông báo', variant: 'success', toaster: this.$toastPosition, solid: true,
      })
    },
    resetDeleteModel() {
      this.deleteModel.id = null
      this.deleteModel.userTypeNewId = null
    },
    showToast(variant, nameAlert) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text: nameAlert,
          variant,
        },
      })
    },
  },

}
</script>

<style lang="scss">
.style-code span {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
  padding: 1px 9px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #227ff4;
}
.search-input {
  width: 400px;
}
.table__chucnang{
  width:150px;
  text-align: center;
}
</style>
